<template>
  <modal
    :title="title"
    :show="show"
    :width="650"
    @close="$emit('close')"
    :is-loading="loading"
    :fullscreen="true"
  >
    <v-form
      ref="form"
      lazy-validation
      class="d-flex flex-column"
      @submit.prevent="store"
    >
      <v-container class="pa-7 elevation-1">
        <v-row>
          <v-col cols="6">
            <v-row>
              <div class="form-group col col-5">
                <label class="text-caption font-weight-bold"> Username </label>
                <v-text-field
                  single-line
                  dense
                  hide-details="auto"
                  :rules="$rules('Username', 'required')"
                  v-model="form.username"
                  placeholder="Ketik disini"
                />
              </div>
              <div class="form-group col col-7">
                <label class="text-caption font-weight-bold"> Email </label>
                <v-text-field
                  single-line
                  dense
                  hide-details="auto"
                  :rules="$rules('Email', 'required|email')"
                  v-model="form.email"
                  placeholder="Ketik disini"
                />
              </div>
              <div class="form-group col col-12">
                <label class="text-caption font-weight-bold"> Name </label>
                <v-text-field
                  single-line
                  dense
                  hide-details="auto"
                  :rules="$rules('Name', 'required')"
                  v-model="form.name"
                  placeholder="Ketik disini"
                />
              </div>
              <div class="form-group col col-6">
                <label class="text-caption font-weight-bold"> Telepon </label>
                <v-text-field
                  single-line
                  dense
                  hide-details="auto"
                  :rules="$rules('Telepon', 'required|phone')"
                  v-model="form.phone"
                  placeholder="Ketik disini"
                />
              </div>
              <div class="form-group col col-6">
                <label class="text-caption font-weight-bold">
                  Tanggal Lahir
                </label>
                <date-picker
                  single-line
                  dense
                  hide-details="auto"
                  :rules="$rules('Tanggal Lahir ', 'required')"
                  v-model="form.dob"
                  :select-on-click="true"
                  :show-save-cancel="false"
                />
              </div>
            </v-row>
          </v-col>
          <v-col cols="6">
            <div class="form-group">
              <label class="text-caption font-weight-bold"> Alamat </label>
              <v-textarea
                single-line
                dense
                hide-details="auto"
                :rules="$rules('Alamat', 'required')"
                v-model="form.address"
                placeholder="Ketik disini"
                rows="5"
              />
            </div>
            <div class="form-group">
              <label class="text-caption font-weight-bold"> Status </label>
              <v-select
                single-line
                dense
                hide-details="auto"
                :rules="$rules('Status', 'required')"
                v-model="form.record_status"
                placeholder="Pilih salah satu"
                :items="$helper.recordStatusList()"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pa-7 elevation-1 mt-4">
        <v-row>
          <v-col cols="12">
            <div class="form-group">
              <label class="text-caption font-weight-bold">
                Alamat Pengiriman
              </label>
              <v-row dense class="mt-2">
                <v-col cols="2">
                  <label class="text-caption font-weight-bold">
                    Nama Penerima
                  </label>
                </v-col>
                <v-col cols="2">
                  <label class="text-caption font-weight-bold">
                    No Telepon
                  </label>
                </v-col>
                <v-col cols="3">
                  <label class="text-caption font-weight-bold"> Kota </label>
                </v-col>
                <v-col cols="4">
                  <label class="text-caption font-weight-bold"> Alamat </label>
                </v-col>
                <v-col> </v-col>
              </v-row>
              <v-row
                dense
                v-for="(rw, index) in deliveryAddress"
                :key="`del-addresses-${index}`"
              >
                <v-col cols="2">
                  <v-text-field
                    single-line
                    dense
                    hide-details="auto"
                    :rules="$rules('Nama Penerima', 'required')"
                    v-model="rw.name"
                    placeholder="Ketik disini"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    single-line
                    dense
                    hide-details="auto"
                    :rules="$rules('Nomor Telepon', 'required|phone')"
                    v-model="rw.phone_number"
                    placeholder="Ketik disini"
                  />
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    single-line
                    dense
                    hide-details="auto"
                    :rules="$rules('Kota', 'required')"
                    v-model="rw.city_id"
                    placeholder="Pilih salah satu"
                    :items="cities"
                    item-text="name"
                    item-value="id"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    single-line
                    dense
                    hide-details="auto"
                    :rules="$rules('Alamat', 'required')"
                    v-model="rw.address"
                    placeholder="Ketik disini"
                  />
                </v-col>
                <v-col>
                  <v-btn
                    color="error"
                    icon
                    rounded
                    @click="() => deleteDeliveryAddress(index)"
                    v-if="deliveryAddress.length > 1"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                  <v-btn
                    color="accent"
                    icon
                    rounded
                    @click="() => addDeliveryAddress()"
                    v-if="index === deliveryAddress.length - 1"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <template slot="action">
      <v-btn color="secondary" class="mr-3" @click="$emit('close')" outlined>
        Batal
      </v-btn>
      <v-btn color="secondary" @click="store"> Simpan </v-btn>
    </template>
  </modal>
</template>

<script>
import { mapState } from "vuex";
const EMPTY_ADDRESS = {
  city_id: null,
  address: "",
  name: "",
  phone_number: ""
};
const EMPTY_OBJ = {
  id: undefined,
  username: "",
  email: "",
  name: "",
  phone: "",
  dob: undefined,
  address: "",
  record_status: ""
};
export default {
  name: "ProductCategoryForm",
  components: {
    Modal: () =>
      import(/* webpackChunkName: 'modal' */ "@/components/atom/Modal"),
    DatePicker: () =>
      import(/* webpackChunkName: 'modal' */ "@/components/atom/date-picker")
  },
  props: {
    isCreate: {
      default: true
    },
    show: {
      default: false
    },
    title: {
      default: ""
    },
    data: {
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    loading: false,
    form: EMPTY_OBJ,
    deliveryAddress: [],
    existingDeliveryAddress: []
  }),
  watch: {
    data: function (val) {
      this.form = { ...val };
    },
    show: function (val) {
      if (val)
        this.$nextTick(async () => {
          await this.init();
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
        });
    }
  },
  computed: {
    ...mapState({
      cities: (state) => state.location.kota
    })
  },
  methods: {
    init: async function () {
      if (this.isCreate) {
        this.form = { ...EMPTY_OBJ };
        this.deliveryAddress = [{ ...EMPTY_ADDRESS }];
        this.existingDeliveryAddress = [];
        return;
      }
      this.loading = true;
      try {
        let response = await this.$http.get(
          `/users/${this.form.id}/shipment-addresses`
        );
        let {
          data: { success, data }
        } = response;
        if (success) {
          this.deliveryAddress = [...data.data];
          this.existingDeliveryAddress = [...data.data];
        } else {
          this.deliveryAddress = [{ ...EMPTY_ADDRESS }];
          this.existingDeliveryAddress = [];
        }
      } catch {
        this.deliveryAddress = [{ ...EMPTY_ADDRESS }];
        this.existingDeliveryAddress = [];
      } finally {
        this.loading = false;
      }
    },
    deleteDeliveryAddress: function (index) {
      this.deliveryAddress.splice(index, 1);
    },
    addDeliveryAddress: function () {
      this.deliveryAddress.push({
        ...EMPTY_ADDRESS
      });
    },
    store: async function () {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url = `/resellers/${this.form.id}`;
        let method = "put";
        if (this.isCreate) {
          url = "/resellers";
          method = "post";
        }
        try {
          let newAddress = this.deliveryAddress;
          let deletedAddress = [];
          let updatedAddress = [];
          if (!this.isCreate) {
            newAddress = [...this.deliveryAddress.filter((x) => !x.id)];
            updatedAddress = this.deliveryAddress.filter((x) => x.id);
            const ids = updatedAddress.map((x) => x.id);
            deletedAddress = this.existingDeliveryAddress.filter(
              (x) => !ids.includes(x.id)
            );
          }
          let {
            data: { success, message }
          } = await this.$http[method](url, {
            ...this.form
          });
          if (success) {
            let promises = [];
            if (newAddress.length) {
              newAddress.forEach((x) => {
                promises.push(
                  this.$http.post(
                    `/users/${this.form.id}/shipment-addresses`,
                    x
                  )
                );
              });
            }
            if (updatedAddress.length) {
              updatedAddress.forEach((x) => {
                promises.push(
                  this.$http.put(
                    `/users/${this.form.id}/shipment-addresses/${x.id}`,
                    x
                  )
                );
              });
            }
            if (deletedAddress.length) {
              deletedAddress.forEach((x) => {
                promises.push(
                  this.$http.delete(
                    `/users/${this.form.id}/shipment-addresses/${x.id}`
                  )
                );
              });
            }
            if (promises.length > 0) {
              await Promise.all(promises);
            }
            this.$dialog.notify.info("Data berhasil disimpan", {
              position: "top-right",
              timeout: 3000
            });
            this.$emit("onSuccess");
            this.close();
          } else
            this.$dialog.notify.error(message, {
              position: "top-right",
              timeout: 3000
            });
        } catch (error) {
          // console.log("err", error);
        } finally {
          this.loading = false;
        }
      }
    },
    close: function () {
      this.$emit("close");
    }
  }
};
</script>
